// Generate an array of days between the start and end date
import moment from 'moment';
import _mergeWith from 'lodash/mergeWith';
import { WEEKDAYS } from '../variables/dates';
import _get from 'lodash/get';

export const generateDateLabels = (start, end) => {
    let labels = [];
    let date = start.clone();

    while (date.isSameOrBefore(end)) {
        labels.push(date.format('YYYY-MM-DD'));
        date.add(1, 'day');
    }

    return labels;
};

export const formatDataForStats = (callback, obj, key = false) => {
    const value = key ? obj[key] : obj;

    if (!value?.handle)
        return Object.keys(value).forEach((subKey) => formatDataForStats(callback, value, subKey));

    callback(value, key);
};

/**
 * Applica le definizione delle statistiche ai dati grezzi e ritorna un array di doc
 *
 * @param querySnapshot
 * @param definition
 *
 * @returns {Array}
 */
export const handleDefinition = (querySnapshot, definition) => {
    const rawList = [];
    querySnapshot.forEach((doc) => {
        const docData = typeof doc.data === 'function' ? doc.data() : doc;
        rawList.push({ id: doc.id, ...docData });
        Object.keys(definition).forEach((key) => {
            //Due livelli di profondità
            if (!definition[key].handle) {
                Object.keys(definition[key]).forEach((subKey) => {
                    if (!definition[key][subKey]?.handle) return;

                    definition[key][subKey].handle(definition[key][subKey], docData);
                });
            } else {
                definition[key].handle(definition[key], docData);
            }
        });
    });

    return rawList;
};

export const convertIdsToProductName = (labels, products, maxLength = false) => {
    if (!labels || !products) return undefined;

    return labels.map((label) => {
        const product = products[label];

        let name = product?.name || label;

        if (maxLength !== false) {
            if (name.length > maxLength) name = name.substr(0, maxLength) + '...';
        }

        return name;
    });
};

export const convertIdsToMenuName = (labels, menus, maxLength = false) => {
    if (!labels || !menus) return undefined;

    return labels.map((label) => {
        const menu = menus[label];

        let name = menu?.name || label;

        if (maxLength !== false) {
            if (name.length > maxLength) name = name.substr(0, maxLength) + '...';
        }

        return name;
    });
};

export const mergeAndSumObjectWithQtyAndPrice = (dest, src) => {
    _mergeWith(dest, src, (dest, src, key) => {
        if (dest) {
            dest.qty += src.qty;
            dest.totalPrice += src?.totalPrice;
            dest.avgPrice += (dest?.totalPrice || 0) / (dest?.qty || 1);
        } else {
            dest = { ...src, avgPrice: (src?.totalPrice || 0) / (src?.qty || 1) };
        }

        return dest;
    });
};

/**
 *
 * @returns {{data: {x: string|*, y: number}[], name: *}[]}
 */
export const initializeTimeslot = () =>
    [...WEEKDAYS, 'Total'].map((i) => ({
        name: i,
        min: 0,
        max: 10,
        data: new Array(25).fill({}).map((i, index) => ({
            x: index === 24 ? 'Total' : index.toString().padStart(2, 0),
            y: 0
        }))
    }));

export const generateTimeslot = (item, doc, timeslotKey) => {
    const orderDayWeek = WEEKDAYS[moment(doc.createdAt.toDate()).format('e')];
    let dataDayIndex = item.data.findIndex((i) => i.name === orderDayWeek);

    const timeslots = _get(doc, timeslotKey, {});
    item.min = 0;
    item.max = 0;

    if (!timeslots) return item;
    for (const time in timeslots) {
        const timeIndex = parseInt(time.substring(0, 2));
        const timeValue = timeslots[time];

        item.data[dataDayIndex].data[timeIndex]['y'] += timeValue.qty;

        item.min = Math.min(item.min || 0, timeValue.qty || 0);
        item.max = Math.max(item.max || 10, timeValue.qty || 10);

        // Totali per ora e giorno
        item.data[7].data[timeIndex]['y'] += timeValue.qty;
        item.data[dataDayIndex].data[24]['y'] += timeValue.qty;
    }

    return item;
};

/**
 * Genera i dati per un grafico a torta, inserendo il valore nella posizione relativa all'array di labels
 *
 * @param item
 * @param sourceObj
 */
export const generatePie = (item, sourceObj) => {
    for (const sourceId in sourceObj || {}) {
        if (!item.labels.includes(sourceId)) item.labels.push(sourceId);

        let index = item.labels.indexOf(sourceId);

        if (!item.data[index]) item.data[index] = 0;

        item.data[index] += sourceObj[sourceId]?.qty || 0;
    }
};
