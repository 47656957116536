export const ORDER_TYPES = {
    table: {id: "table", name: "Al tavolo", icon: "shop", color: "danger"},
    pos: {id: "pos", name: "Al banco", icon: "laptop", color: "orange"},
    delivery: {id: "delivery", name: "Consegna a domicilio", icon: "delivery-fast", color: "primary"},
    takeaway: {id: "takeaway", name: "Asporto", icon: "cart", color: "green"},
}

export const ORDER_TYPES_ID = Object.keys(ORDER_TYPES).reduce((acc, key) => {
    acc[ORDER_TYPES[key].id.toUpperCase()] = ORDER_TYPES[key]
    acc[ORDER_TYPES[key].id] = ORDER_TYPES[key]
    return acc
}, {});

export const ORDER_STATUS = {
    CLOSED: 'closed',
    OPEN: 'open',
    ARCHIVED: 'archived'
}
