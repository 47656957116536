import './wdyr';
import React, { StrictMode, lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import '@/assets/plugins/nucleo/css/nucleo.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/scss/argon-dashboard-react.scss';
import 'react-loading-skeleton/dist/skeleton.css';

import IsAuth from 'components/IsAuth.js';

import {
    MainStateProvider as StateProvider,
    ProductsAndMenusProvider,
    StatsDataProvider
} from 'store';
import ErrorDisplay from './components/ErrorDisplay';
import Loading from './components/GUI/Loading';

const PrintQrCodes = lazy(() => import('views/pages/PrintQrCodes.js'));
const AdminLayout = lazy(() => import('layouts/Admin.js'));
const AuthLayout = lazy(() => import('layouts/Auth.js'));
const AuthAction = lazy(() => import('views/pages/AuthAction.js'));

ReactDOM.render(
    <StrictMode>
        <ErrorBoundary FallbackComponent={ErrorDisplay}>
            <Suspense fallback={<Loading />}>
                <BrowserRouter>
                    <Switch>
                        <Route path="/auth-action" render={(props) => <AuthAction />} />
                        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
                        <StateProvider>
                            <ProductsAndMenusProvider>
                                <StatsDataProvider>
                                    <Route
                                        path="/admin/orders"
                                        render={() => <Redirect to="/orders" />}
                                    />
                                    <Route
                                        path="/qrCodesPrint"
                                        render={() => (
                                            <IsAuth>
                                                <PrintQrCodes />
                                            </IsAuth>
                                        )}
                                    />
                                    <Route
                                        path="/"
                                        render={(props) => (
                                            <IsAuth>
                                                <AdminLayout {...props} />
                                            </IsAuth>
                                        )}
                                    />
                                </StatsDataProvider>
                            </ProductsAndMenusProvider>
                        </StateProvider>
                    </Switch>
                </BrowserRouter>
            </Suspense>
        </ErrorBoundary>
    </StrictMode>,
    document.getElementById('root')
);
