export const DATE_FORMAT = 'DD/MM/YYYY';
export const TIME_FORMAT = 'HH:mm';
export const DATETIME_FORMAT = DATE_FORMAT + ' ' + TIME_FORMAT;

export const DATE_FORMAT_JS = 'dd/MM/yyyy';

export const WEEKDAYS = ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'];
export const WEEKDAYS_NUMBER = [0, 1, 2, 3, 4, 5, 6];

export const DEFAULT_DATE = '1970-01-01';
