import React from 'react';
import { Puff } from 'react-loader-spinner';
import { useMainStore } from '../../store';

const Loading = ({ loading = true }) => {
    const { business } = useMainStore();

    if (loading)
        return (
            <div className="d-flex justify-content-center align-items-center">
                <Puff
                    type={'Puff'}
                    color={business?.color?.primary || '#60293a'}
                    height={100}
                    width={100}
                />
            </div>
        );

    return null;
};

export default Loading;
