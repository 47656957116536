const firebaseConfig = {
    apiKey: "AIzaSyBSN7Xlye3vupZU5uNsRuMNjp26A_rTD-s",
    authDomain: "paiazo.firebaseapp.com",
    databaseURL: "https://paiazo.firebaseio.com",
    projectId: "paiazo",
    storageBucket: "paiazo.appspot.com",
    messagingSenderId: "54274109942",
    appId: "1:54274109942:web:e15c7af5ed19231fd717d7",
    measurementId: "G-065WGFKZ2S"
  };
  
  export { firebaseConfig }