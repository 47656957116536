const productTypes = [
    {id:'', label:'Semplice'},
    {id: 'modular', label:'Componibile'}
];

export const PRODUCT_DOC_TYPE = {
    PRODUCT: 'products',
    STORE_PRODUCT: 'storeProducts'
}


export { productTypes }
