import React, { useEffect } from 'react';
import StateContext, { firestore, auth, firebaseApp } from './StateContext';
import defaultState from './defaultState';
import { useHistory, useLocation } from 'react-router-dom';

import _merge from 'lodash/merge';
import Loading from '../components/GUI/Loading';

let isSSO = false;
function StateProvider(props) {
    const [loading, setLoading] = React.useState(true);
    const [currentUser, setCurrentUser] = React.useState(defaultState.currentUser);
    const [business, setBusiness] = React.useState(null);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        auth.onAuthStateChanged(async (user) => {
            checkURL();
            if (user) {
                let isAdmin = false;
                try {
                    isAdmin = (
                        await firebaseApp.functions('europe-west1').httpsCallable('profile')()
                    ).data.isAdmin;
                } catch (e) {
                    console.error(e);
                }

                await setUser({ ...user, isAdmin });
            } else {
                setUser(null);
                if (!isSSO && !location.pathname.startsWith('/auth')) {
                    history.replace('/auth/login');
                } else if (isSSO) {
                    window.location.href = process.env.REACT_APP_CUSTOMERS_BACKEND_URL;
                }
            }
        });
    }, []);

    useEffect(() => {
        if (location.pathname.startsWith('/auth')) return;
    }, [location.pathname]);

    useEffect(() => {
        if (location.pathname.startsWith('/auth')) return;

        if (auth.currentUser && !auth.currentUser.emailVerified) {
            history.push('/verify-email');
        }
    }, [location.pathname]);

    function checkURL() {
        if (
            process?.env?.NODE_ENV !== 'development' &&
            !window.location.href.includes('admin-paiazo-staging') &&
            !window.location.href.includes('admin.primopos.it')
        ) {
            window.location.href = 'https://admin.primopos.it';
        }
    }

    async function setUser(user) {
        if (user) {
            window.OneSignal = window.OneSignal || [];
            await window.OneSignal.push(async function () {
                await window.OneSignal.init({
                    appId: process?.env?.REACT_APP_ONESIGNAL_APP_ID
                });
            });
            const querySnapshot = await firestore.collection('users').doc(user.uid).get();

            if (!querySnapshot.exists) {
                setBusiness(null);
                setCurrentUser(null);
            } else {
                const userData = querySnapshot.data();
                isSSO = userData?.isSSO === true;

                const businesses = await querySnapshot.ref.collection('business').get();
                let businessId = businesses.docs[0].id;
                if (!userData?.currentBusiness) {
                    businessId = businesses.docs[0].id;
                    await firestore.collection('users').doc(user.uid).set(
                        {
                            currentBusiness: businessId
                        },
                        { merge: true }
                    );
                } else {
                    businessId = userData.currentBusiness;
                }
                const _business = await firestore.collection('business').doc(businessId).get();
                console.log('businessId', businessId);

                setCurrentUser({
                    ...user,
                    hasMorebusiness: user.isAdmin || businesses.docs.length > 1,
                    businesses: businesses.docs.map((d) => d.id)
                });

                setBusiness({ id: businessId, ..._business.data() });
                manageTags(_business.id);

                if (_business.data().color)
                    for (let [key, value] of Object.entries(_business.data().color)) {
                        document.documentElement.style.setProperty(
                            `--business-color-${key}`,
                            value
                        );
                    }
            }
        }
        setLoading(false);
    }

    function updateBusiness(data) {
        setBusiness(_merge(business, data));
    }

    async function manageTags(businessId) {
        if (!window.OneSignal || !window.OneSignal.on) return false;
        window.OneSignal.on('notificationDisplay', function (event) {
            var audio = new Audio(
                'https://firebasestorage.googleapis.com/v0/b/paiazo.appspot.com/o/swiftly.ogg?alt=media&token=0e0a44d1-2206-4e05-bf44-c41b89b7e531'
            );
            audio.play();
        });
        await window.OneSignal.deleteTag('businessId');
        await window.OneSignal.sendTag('businessId', businessId);
    }

    async function logout() {
        setCurrentUser(null);
        await auth.signOut();
    }

    const state = React.useMemo(
        () => ({
            currentUser,
            setUser,
            logout,
            business,
            updateBusiness
        }),
        [currentUser, business]
    );

    return (
        <StateContext.Provider value={state}>
            <style>
                :root{'{'}
                --primary: {business?.color?.primary || '#5a2434'}
                {'}'}
            </style>
            {loading || !business ? (
                <div className="text-center" style={{ marginTop: 50 }}>
                    <Loading />
                </div>
            ) : (
                props.children
            )}
        </StateContext.Provider>
    );
}

export default StateProvider;
