import { ORDER_TYPES } from 'variables/orders';
import _set from 'lodash/set';
import { WEEKDAYS } from 'variables/dates';
import moment from 'moment';
import { PRODUCT_DOC_TYPE } from 'variables/products';
import { generatePie, generateTimeslot, initializeTimeslot } from 'helpers/stats';
import _cloneDeep from 'lodash/cloneDeep';

const emptyTimeslots = initializeTimeslot();
export const ordersDefinition = (dateRangeLabels) => ({
    ...['overall', ...Object.keys(ORDER_TYPES)].reduce((acc, type) => {
        _set(acc, `${type}.qty`, {
            handle: (item, orderData) => {
                const labelIndex = dateRangeLabels.indexOf(
                    moment(orderData.createdAt.toDate()).format('YYYY-MM-DD')
                );
                item.data[labelIndex] += orderData[type]?.qty || 0;
            },
            type: 'column',
            name: `Numero Ordini`,
            group: type,
            data: new Array(dateRangeLabels.length).fill(0)
        });

        _set(acc, `${type}.covers`, {
            handle: (item, orderData) => {
                const labelIndex = dateRangeLabels.indexOf(
                    moment(orderData.createdAt.toDate()).format('YYYY-MM-DD')
                );
                item.data[labelIndex] += orderData[type]?.covers || 0;
            },
            name: `Coperti`,
            type: 'column',
            group: type,
            data: new Array(dateRangeLabels.length).fill(0)
        });

        _set(acc, `${type}.avgPrice`, {
            handle: (item, orderData) => {
                const labelIndex = dateRangeLabels.indexOf(
                    moment(orderData.createdAt.toDate()).format('YYYY-MM-DD')
                );
                item.data[labelIndex] +=
                    (orderData[type]?.totalPrice || 0) / (orderData[type]?.qty || 1);
            },
            name: `Prezzo Medio`,
            group: type,
            data: new Array(dateRangeLabels.length).fill(0)
        });

        return acc;
    }, {}),
    timeslots: {
        handle: (item, orderData) => {
            generateTimeslot(item, orderData, 'overall.timeslot');
        },
        labels: WEEKDAYS,
        name: `Distribuzione ordini`,
        group: 'heatmap',
        data: _cloneDeep(emptyTimeslots)
    },

    income: {
        handle: (item, orderData) => {
            const labelIndex = dateRangeLabels.indexOf(
                moment(orderData.createdAt.toDate()).format('YYYY-MM-DD')
            );

            item.data[labelIndex] += orderData?.overall?.totalPrice || 0;
            item.total = (item.total || 0) + orderData?.overall?.totalPrice;
        },
        name: 'Entrate',
        group: 'counter',
        data: new Array(dateRangeLabels.length).fill(0)
    }
});

export const productsOverallDefinition = (dateRangeLabels) => ({
    outcome: {
        handle: (item, doc) => {
            const labelIndex = dateRangeLabels.indexOf(
                moment(doc.createdAt.toDate()).format('YYYY-MM-DD')
            );
            item.data[labelIndex] += doc?.totalPrice || 0;
            item.total = (item.total || 0) + doc?.totalPrice;
        },
        name: 'Uscite',
        group: 'counter',
        data: new Array(dateRangeLabels.length).fill(0)
    },
    menu: {
        handle: (item, doc) => {
            generatePie(item, doc?.menu);
        },
        name: 'Uscite',
        group: 'counter',
        data: new Array(dateRangeLabels.length).fill(0),
        labels: []
    }
});

const productsResume = (item, doc, dateRangeLabels) => {
    if (!item.data[doc.productId])
        item.data[doc.productId] = {
            qty: 0,
            avgPrice: 0,
            id: doc.productId,
            total: 0,
            soldQty: {
                name: 'Venduti',
                data: new Array(dateRangeLabels.length).fill(0)
            },
            soldAvgPrice: {
                name: 'Prezzo Medio',
                data: new Array(dateRangeLabels.length).fill(0)
            },
            soldTotal: {
                name: 'Prezzo Medio',
                data: new Array(dateRangeLabels.length).fill(0)
            },
            heatmap: {
                data: _cloneDeep(emptyTimeslots)
            },
            menuPie: {
                data: [],
                labels: []
            }
        };

    const labelIndex = dateRangeLabels.indexOf(moment(doc.createdAt.toDate()).format('YYYY-MM-DD'));

    item.data[doc.productId].qty += doc?.qty || 0;
    item.data[doc.productId].total += doc?.totalPrice || 0;
    item.data[doc.productId].avgPrice =
        item.data[doc.productId].total / item.data[doc.productId].qty;

    item.data[doc.productId].soldQty.data[labelIndex] += doc?.qty || 0;
    item.data[doc.productId].soldAvgPrice.data[labelIndex] +=
        (doc?.totalPrice || 0) / (doc?.qty || 1);
    item.data[doc.productId].soldTotal.data[labelIndex] += doc?.totalPrice || 0;

    return item;
};

/**
 * La struttura dati di doc è la seguente:
 * {
 *     productId: l'id del product o dello storeProduct
 *     docType: 'products' o 'storeProducts'
 * }
 *
 * @param dateRangeLabels
 * @returns {{topProducts: {name: string, handle: soldProductsDefinition.topProducts.handle}}}
 */
export const soldProductsDefinition = (dateRangeLabels) => ({
    // I prodotti più venduti
    productsLines: {
        //TODO: Capire se meglio metterlo a livello di dettaglio stats
        mutator: (item) => {
            return {
                ...item,
                data: Object.values(item.data),
                labels: Object.keys(item.data)
            };
        },
        qty: {
            handle: (item, doc) => {
                if (doc.docType !== PRODUCT_DOC_TYPE.PRODUCT) return;

                if (!item.data[doc.productId]) item.data[doc.productId] = 0;

                item.data[doc.productId] += doc?.qty || 0;
            },
            name: 'Numero ordinazioni',
            type: 'column',
            data: {}
        },
        avgPrice: {
            handle: (item, doc) => {
                if (doc.docType !== PRODUCT_DOC_TYPE.PRODUCT) return;

                if (!item.data[doc.productId]) item.data[doc.productId] = 0;

                item.data[doc.productId] = (doc?.totalPrice || 0) / (doc?.qty || 1);
            },
            name: 'Prezzo Vendita Medio',
            type: 'line',
            data: {}
        }
    },
    storeProductsResume: {
        handle: (item, doc) => {
            if (doc.docType !== PRODUCT_DOC_TYPE.STORE_PRODUCT) return;

            productsResume(item, doc, dateRangeLabels);

            // Merge timeslot
            generateTimeslot(item.data[doc.productId].heatmap, doc, 'timeslot');
            // console.log('doc', doc.productId, doc.id, doc.timeslot);

            // Generate menu pie for apex chart
            generatePie(item.data[doc.productId].menuPie, doc?.menu);
        },
        name: 'Prodotti magazzino più movimentati',
        data: {}
    },
    productsResume: {
        handle: (item, doc) => {
            if (doc.docType !== PRODUCT_DOC_TYPE.PRODUCT) return;
            productsResume(item, doc, dateRangeLabels);
        },
        name: 'Prodotti più venduti',
        data: {}
    }
});
